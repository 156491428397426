<template>
<Card class="card">
    <template #header>
        <!-- <img alt="user header" src="demo/images/usercard.png"> -->
        <img alt="{{name}} photo" 
            :src="require('@/assets/office-staff/images/' + img_src)">

    </template>
    <template #title>
        {{name}}
        <br />
        <template v-if="acceptsNewPatients">
            <Tag value="Accepting New Patients" icon="pi pi-check"></Tag>
        </template>
        
        <template v-if="telemedicine">
            <Tag value="Telemedicine" severity="success" icon="pi pi-check"></Tag>
        </template>
        
        <template v-if="retired">
            <Tag value="Retired" severity="warning"></Tag>
        </template>
    </template>
    <template #content>
        <p v-for="paragraph in biography" :key="paragraph"> 
            {{paragraph}} 
        </p>
    </template>
    <template #footer>
        <div v-if="certifications" class="certifications">
            Additional certifications: 
            <ul v-for="certification in certifications" :key="certification">
                <li> {{certification}} </li>
            </ul>
        </div>
    </template>
</Card> 
</template>

<script>
import Card from 'primevue/card';
import Tag from 'primevue/tag';
export default {
    name: "Provider",
    components: {
        Card,
        Tag
    },
    props: {
        name: String,
        img_src: String,
        undergrad: String,
        undergrad_year: Number,
        professional_school: String,
        professional_school_year: Number,
        biography: Array,
        certifications: Array,
        acceptsNewPatients: Boolean,
        telemedicine: Boolean,
        retired: Boolean
    }
}
</script>

<style scoped>
    .card {
        width: 60%;
        margin: 15px auto;
    }
    @media screen and (max-width: 500px) {
        .card {
            width: 90%;
            margin: 15px auto;
        }
    }
    img {
        height: 150px;
        width: 150px;
        border-radius: 50%;
    }
    .p-tag {
        margin-right: 15px;
    }

</style>