<template> 
    <Provider v-bind="provider_info"/>
</template>

<script>
import Provider from "@/components/Provider.vue";
export default {
    name: "Jenna Kaup",
    components: {
        Provider
    },
    data() {
        return {
            provider_info: {
                name: "Jenna Kaup",
                img_src: "photo-coming-soon.jpg",
                undergrad: "University of Nebraska Medical Center",
                //undergrad_year: 1981,
                professional_school: "University of Nebraska Medical Center",
                //professional_school_year: 1988,
                biography: [ 
                    "Jenna grew up on a farm outside of Clearwater, NE, graduating high school from Clearwater Public School. She earned both her Bachelor of Science in Nursing and Master of Science in Nursing from The University of Nebraska Medical Center in Omaha. In 2008, she was board certified by the American Association of Nurse Practitioners. She practiced with Avera Medical Group O’Neill for close to seven years before joining Elkhorn Valley Family Medicine in February 2019. ",
                    "Jenna’s special interests in medicine include rural health, family practice, and emergency medicine.",
                    "Jenna loves anything outdoors and spending time with friends and family. She and her husband, Jeremy, are expecting their first child in August 2019."
                ],
                certifications: [
                    "Advanced Cardiac Life Support (ACLS)",
                    "Advanced Trauma Life Support (ATLS)",
                    "Society of Critical Care Medicine - Critical Care Certified (FCCS)",
                    "Pediatric Advanced Life Support (PALS)",
                    "FMCSA Certified Medical Examiner (DOT exams)"
                ],
                acceptsNewPatients: true,
                telemedicine: true
            }
        }
    }
}
</script>

<style scoped>

</style>